import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ROUTES } from '@/_navigation';
import { useLocationDetails } from '@/_navigation/hooks/use-location-details';
import { useMeContext } from '@/_providers/me-provider';
import { CookieManager } from '@/_services';

import { useOnboardingContext } from '../onboarding-provider';

import { WalkthroughContext } from './context';
import type { WalkthroughContextType, WalkthroughType, Walkthroughs } from './types';

const WALKTHROUGHS: Record<WalkthroughType, number> = {
  COURSE_TABS: 0,
  PREP_COURSE_REGISTRATION: 1,
  CLASS_SCHEDULE: 2,
  PREP_COURSE: 3,
  PASS_COURSE: 4,
  LESSON_LIVE_INDICATOR: 5,
};

export function WalkthroughProvider({ children }: { children: ReactNode }) {
  const routeData = useLocationDetails();

  const [walkthroughs, setWalkthroughs] = useState<Walkthroughs>({});
  const { showOnboardingVideo } = useOnboardingContext();
  const [currentWalkthrough, setCurrentWalkthrough] = useState<number>(
    parseInt(localStorage.getItem('current-walkthrough') || '0')
  );

  const { data } = useMeContext();

  const setWalkthroughViewed = useMemo(() => setWalkthroughViewedHoc(data?.id || ''), [data?.id]);
  const getWalkthroughViewed = useMemo(() => getWalkthroughViewedHoc(data?.id || ''), [data?.id]);

  const handleWalkthroughViewed = useCallback(
    (walkthroughType: WalkthroughType) => {
      setWalkthroughViewed(walkthroughType);
      setWalkthroughs((prev) => ({ ...prev, [walkthroughType]: { ...prev[walkthroughType], show: false } }));
      setCurrentWalkthrough(currentWalkthrough + 1);
    },
    [setWalkthroughViewed, currentWalkthrough]
  );

  const startWalkthrough = useCallback(
    (walkthroughType: WalkthroughType) => {
      if (getWalkthroughViewed(walkthroughType)) {
        return;
      }

      localStorage.setItem('current-walkthrough', currentWalkthrough.toString());
      setWalkthroughs((prev) => ({ ...prev, [walkthroughType]: { type: walkthroughType, show: true } }));
    },
    [getWalkthroughViewed, currentWalkthrough]
  );

  useEffect(() => {
    if (showOnboardingVideo) {
      return;
    }

    const keys = Object.keys(WALKTHROUGHS) as WalkthroughType[];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!getWalkthroughViewed(key)) {
        if (i >= currentWalkthrough) {
          if (key === 'CLASS_SCHEDULE' && routeData.data.path !== ROUTES.UNREGISTERED_DETAILS.path) {
            continue;
          }
          startWalkthrough(key);
          break;
        } else if (key === 'CLASS_SCHEDULE' && routeData.data.path === ROUTES.UNREGISTERED_DETAILS.path) {
          startWalkthrough(key);
          break;
        }
      }
    }
  }, [currentWalkthrough, getWalkthroughViewed, showOnboardingVideo, startWalkthrough, routeData]);

  const value: WalkthroughContextType = useMemo(
    () => ({
      walkthroughs,
      setWalkthroughViewed: handleWalkthroughViewed,
      startWalkthrough,
    }),
    [walkthroughs, startWalkthrough, handleWalkthroughViewed]
  );

  return <WalkthroughContext.Provider value={value}>{children}</WalkthroughContext.Provider>;
}

function setWalkthroughViewedHoc(userId: string) {
  return (type: WalkthroughType) => CookieManager.set(`walkthrough-viewed-${userId}-${type}`, 'true');
}

function getWalkthroughViewedHoc(userId: string) {
  return (type: WalkthroughType) => !!CookieManager.get(`walkthrough-viewed-${userId}-${type}`);
}
