import { useCallback } from 'react';

import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.${pathToClassName(ROUTES.IN_PROGRESS.relativePath)} .lesson-live-indicator`,
    content: 'When a lesson is live, you will see this indicator.',
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: `.${pathToClassName(ROUTES.IN_PROGRESS.relativePath)} .prep-course-lesson-status-button`,
    content: 'You can click the lesson button to join the live session.',
    disableScrolling: false,
  },
];

export function LessonLiveWalkthrough() {
  const { setWalkthroughViewed } = useWalkthroughContext();

  const onComplete = useCallback(() => {
    setWalkthroughViewed('LESSON_LIVE_INDICATOR');
  }, [setWalkthroughViewed]);

  return <Walkthrough run steps={steps} continuous onComplete={onComplete} showSkipButton />;
}
