import { useCallback } from 'react';

import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName, useIsMounted } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.course-tab-${pathToClassName(ROUTES.UNREGISTERED.relativePath)}`,
    content:
      'Plan Section is now named Unregistered, you can view all your available courses and register with your desired course/s',
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: `.course-tab-${pathToClassName(ROUTES.IN_PROGRESS.relativePath)}`,
    content:
      'Prep section is now named In Progress, you can join your live lessons and view your course resources from this section',
    disableScrolling: true,
  },
  {
    target: `.course-tab-${pathToClassName(ROUTES.COMPLETE.relativePath)}`,
    content:
      'Pass section is now named Completed, you can register for an exam or submit your exam result from this section',
    disableScrolling: true,
    locale: {
      last: 'Continue',
    },
  },
];

export function CoursesTabWalkthrough() {
  const ready = useIsMounted({ delay: 500 });

  const { setWalkthroughViewed } = useWalkthroughContext();

  const onComplete = useCallback(() => {
    setWalkthroughViewed('COURSE_TABS');
  }, [setWalkthroughViewed]);

  return <Walkthrough run steps={steps} continuous onComplete={onComplete} ready={ready} showSkipButton />;
}
