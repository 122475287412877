import { useCallback } from 'react';

import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName, useIsMounted } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.${pathToClassName(ROUTES.IN_PROGRESS.relativePath)} .prep-course-card`,
    content: 'Courses are designed to help you prepare for your exams.',
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: `.${pathToClassName(ROUTES.IN_PROGRESS.relativePath)} .prep-course-card .prep-course-detail-button`,
    content: 'You can view the details of each in progress course by clicking on the information button.',
    disableScrolling: true,
  },
  {
    target: `.${pathToClassName(ROUTES.IN_PROGRESS.relativePath)} .prep-course-card .prep-course-resources-button`,
    content: 'You can access the course resources by clicking on the Course Resources button.',
    disableScrolling: true,
  },
  {
    target: `.${pathToClassName(ROUTES.IN_PROGRESS.relativePath)} .prep-course-card .prep-course-lesson-status-button`,
    content: 'Lesson status button helps you view the schedule and status of each lesson.',
    disableScrolling: true,
    locale: {
      last: 'Continue',
    },
  },
];

export function PrepCourseWalkthrough() {
  const ready = useIsMounted({ delay: 1000 });

  const { setWalkthroughViewed } = useWalkthroughContext();

  const onComplete = useCallback(() => {
    setWalkthroughViewed('PREP_COURSE');
  }, [setWalkthroughViewed]);

  return <Walkthrough run steps={steps} continuous onComplete={onComplete} ready={ready} showSkipButton />;
}
