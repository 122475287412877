import { useCallback } from 'react';

import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName, useIsMounted } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.${pathToClassName(ROUTES.COMPLETE.relativePath)} .prep-course-card`,
    content: 'Completed Courses will appear under the Completed section.',
    disableBeacon: true,
    disableScrolling: false,
  },
  {
    target: `.${pathToClassName(ROUTES.COMPLETE.relativePath)} .schedule-exam-button`,
    content: 'You can schedule an exam by clicking on the Schedule Exam button.',
    disableScrolling: false,
    locale: {
      last: 'Finish',
    },
  },
];

export function PassCourseWalkthrough() {
  const ready = useIsMounted({ delay: 500 });

  const { setWalkthroughViewed } = useWalkthroughContext();

  const onComplete = useCallback(() => {
    setWalkthroughViewed('PASS_COURSE');
  }, [setWalkthroughViewed]);

  return <Walkthrough run steps={steps} continuous onComplete={onComplete} ready={ready} showSkipButton />;
}
